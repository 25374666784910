import utils from 'hey-utils';
import {clone, isNumber} from 'xe-utils';
import {toMoney} from "number-string";

const rclass = /[\t\r\n\f]/g;

export const CopyObj = (target, original) => {
  if (original) {
    //解除obs
    original = clone(original, true);
    Object.keys(target).forEach(key => {
      target[key] = original[key];
    });
  }
}

export const ArrToObj = (target, getKey, getVal) => {
  let obj = {};
  if (target) {
    for (const item of target) {
      obj[getKey(item)] = getVal ? getVal(item) : item;
    }
  }
  return obj;
}

/**
 * 金额格式化
 * @param cellValue
 * @param defVal
 * @returns {string|string}
 * @constructor
 */
export const ToMoney = (cellValue, defVal = '') => {
  return cellValue && cellValue !== 0 ? toMoney(cellValue, {
    maxPrecision: 2,
    useParens: false,
    symbol: ''
  }) : defVal;
}

export const ToNumNumber = (cellValue, defVal = '') => {
  if (!cellValue || cellValue === '0') {
    return defVal || ''
  }
  return toMoney(cellValue, {
    maxPrecision: 5,
    minPrecision: 2,
    useParens: false,
    symbol: ''
  });
}

export const DxMoney = (number, defVal = '') => {
  if (!isNumber(number) || number === 0) {
    return "零元整";
  }

  let currencyDigits = Math.abs(number);

  // Constants:
  let MAXIMUM_NUMBER = 99999999999.99;
  // Predefine the radix characters and currency symbols for output:
  let CN_ZERO = "零";
  let CN_ONE = "壹";
  let CN_TWO = "贰";
  let CN_THREE = "叁";
  let CN_FOUR = "肆";
  let CN_FIVE = "伍";
  let CN_SIX = "陆";
  let CN_SEVEN = "柒";
  let CN_EIGHT = "捌";
  let CN_NINE = "玖";
  let CN_TEN = "拾";
  let CN_HUNDRED = "佰";
  let CN_THOUSAND = "仟";
  let CN_TEN_THOUSAND = "万";
  let CN_HUNDRED_MILLION = "亿";
  let CN_SYMBOL = "";
  let CN_DOLLAR = "元";
  let CN_TEN_CENT = "角";
  let CN_CENT = "分";
  let CN_INTEGER = "整";

// letiables:
  let integral;    // Represent integral part of digit number.
  let decimal;    // Represent decimal part of digit number.
  let outputCharacters;    // The output result.
  let parts;
  let digits, radices, bigRadices, decimals;
  let zeroCount;
  let i, p, d;
  let quotient, modulus;

// Validate input string:
  currencyDigits = currencyDigits.toString();
  if (currencyDigits === "") {
    return "";
  }
  if (currencyDigits.match(/[^,.\d]/) != null) {
    return "";
  }
  if ((currencyDigits).match(/^((\d{1,3}(,\d{3})*(.((\d{3},)*\d{1,3}))?)|(\d+(.\d+)?))$/) == null) {
    return "";
  }

// Normalize the format of input digits:
  currencyDigits = currencyDigits.replace(/,/g, "");    // Remove comma delimiters.
  currencyDigits = currencyDigits.replace(/^0+/, "");    // Trim zeros at the beginning.
  // Assert the number is not greater than the maximum number.
  if (Number(currencyDigits) > MAXIMUM_NUMBER) {
    return "";
  }

// Process the coversion from currency digits to characters:
  // Separate integral and decimal parts before processing coversion:
  parts = currencyDigits.split(".");
  if (parts.length > 1) {
    integral = parts[0];
    decimal = parts[1];
    // Cut down redundant decimal digits that are after the second.
    decimal = decimal.substr(0, 2);
  } else {
    integral = parts[0];
    decimal = "";
  }
  // Prepare the characters corresponding to the digits:
  digits = [CN_ZERO, CN_ONE, CN_TWO, CN_THREE, CN_FOUR, CN_FIVE, CN_SIX, CN_SEVEN, CN_EIGHT, CN_NINE];
  radices = ["", CN_TEN, CN_HUNDRED, CN_THOUSAND];
  bigRadices = ["", CN_TEN_THOUSAND, CN_HUNDRED_MILLION];
  decimals = [CN_TEN_CENT, CN_CENT];
  // Start processing:
  outputCharacters = "";
  // Process integral part if it is larger than 0:
  if (Number(integral) > 0) {
    zeroCount = 0;
    for (i = 0; i < integral.length; i++) {
      p = integral.length - i - 1;
      d = integral.substr(i, 1);
      quotient = p / 4;
      modulus = p % 4;
      if (d === "0") {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          outputCharacters += digits[0];
        }
        zeroCount = 0;
        outputCharacters += digits[Number(d)] + radices[modulus];
      }
      if (modulus === 0 && zeroCount < 4) {
        outputCharacters += bigRadices[quotient];
      }
    }
    outputCharacters += CN_DOLLAR;
  }
  // Process decimal part if there is:
  if (decimal !== "") {
    for (i = 0; i < decimal.length; i++) {
      d = decimal.substring(i, 1);
      if (d !== "0") {
        outputCharacters += digits[Number(d)] + decimals[i];
      }
    }
  }
  // Confirm and return the final output string:
  if (outputCharacters === "") {
    outputCharacters = CN_ZERO + CN_DOLLAR;
  }
  if (decimal === "") {
    outputCharacters += CN_INTEGER;
  }
  outputCharacters = CN_SYMBOL + outputCharacters;
  return (number < 0 ? '负' : '') + outputCharacters;
}

export default utils.extend({}, utils, {
  getClass(elem) {
    return (elem.getAttribute && elem.getAttribute('class')) || '';
  },
  hasClass(elem, selector) {
    let className;
    className = ` ${selector} `;
    if (elem.nodeType === 1 && (` ${this.getClass(elem)} `)
      .replace(rclass, ' ')
      .indexOf(className) > -1) {
      return true;
    }

    return false;
  }
});
