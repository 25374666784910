<template>
  <div class="login">
    <div class="login-left">
      <div class="login-left-box">
        <img class="login-left-fxy" v-if="webConfig.loginLogo" :src="'/api'+webConfig.loginLogo">
        <img class="login-left-fxy" v-else src="../assets/login/login-fxy.png">
        <h1 class="login-left-title">{{ webConfig.loginSlogan || '企业私人订制财务系统' }}</h1>
        <div class="text-18px mt-16px tracking-2px">{{ webConfig.loginSubSlogan || '财务管理更安全、更私密、更简单！' }}</div>
        <img class="login-left-img" src="../assets/login/login-img.png">
      </div>
    </div>
    <div class="login-right">
      <div class="login-right-top">
        <template v-if="GIT==1">
          <a :href="GIT_URL" target="_blank">
            <i class="fa fa-link"></i>
            {{ GIT_TITLE }}
          </a>
          <DropdownCustom class="mx-16px" placement="left-start" trigger="hover" :toggleIcon="false">
            <span> <i class="fa fa-wechat"></i> {{ CONTACT_TITLE }}</span>
            <template #content>
              <div class="w-250px h-250px rounded-md overflow-hidden">
                <img class="w-full" v-if="webConfig.qrCode" :src="'/api'+webConfig.qrCode">
                <img class="w-full" v-else src="../assets/code.png">
              </div>
            </template>
          </DropdownCustom>
        </template>
      </div>
      <div class="login-right-center">
        <transition name="scale" mode="out-in">
          <component :is="viewName" @changeView="changeView"/>
        </transition>
      </div>
      <div class="login-right-copyright">
        <a class="site" :href="GIT_URL" target="_blank">{{ webConfig.copyright || 'Powered by ' + APP_COPM }}</a> {{ year }}
        <a v-if="webConfig.icp" href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">{{ webConfig.icp }}</a>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * <p>****************************************************************************</p>
 * <p><b>Copyright © 2010-2022纷析云（杭州）科技有限公司All Rights Reserved<b></p>
 * <ul style="margin:15px;">
 * <li>Description : </li>
 * <li>Version     : 1.0</li>
 * <li>Creation    : 2022年09月22日</li>
 * <li>@author     : ____′↘夏悸</li>
 * </ul>
 * <p>****************************************************************************</p>
 */
import LoginForm from "@/views/login/LoginForm.vue";
import ForgotPassword from "@/views/login/ForgotPassword.vue";
import Registered from "@/views/login/Registered.vue";
import WxLogin from "@/views/login/WxLogin.vue";
import {mapState} from "vuex";

export default {
  name: "Login",
  components: {LoginForm, ForgotPassword, Registered, WxLogin},
  data() {
    return {
      year: new Date().getFullYear(),
      viewName: 'LoginForm'
    }
  },
  computed: {
    ...mapState(['webConfig'])
  },
  methods: {
    changeView(toView) {
      this.viewName = toView;
    }
  },
  created() {
    localStorage.removeItem("currentTab");
    if (this.webConfig.wechatLogin) {
      this.viewName = 'WxLogin';
    }
  }
}
</script>

<style scoped lang="less">
.login {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  display: flex;

  &-left {
    width: 60vw;
    background-image: url("../assets/login/login-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    &-fxy {
      height: 30px;
    }

    &-img {
      margin-top: 60px;
      width: 550px;
    }

    &-title {
      width: 591px;
      height: 84px;
      font-size: 50px;
      font-weight: bold;
      color: #000000;
      line-height: 84px;
      margin-top: 20px;
      letter-spacing: -2px;
    }
  }

  &-right {
    flex: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    &-top {
      display: flex;
      justify-content: flex-end;
      margin: 16px;
    }

    &-center {
      flex: 1;
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
    }

    &-copyright {
      text-align: center;
      color: rgba(192, 196, 204, 1);
      padding: 32px;
      margin-bottom: 64px;

      .site:hover {
        color: #ff6600 !important;
        text-decoration: underline !important;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .login .login-left {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .login {
    .login-frame {
      width: 80%;
    }
  }
}
</style>
